import React, { Component } from 'react'
import Layout from '../components/Layout'

class index extends Component {
  render() {
    return (
		<Layout>
            <section className="content-section-md">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <h2>What can i do ?</h2>
                        </div>
                        <div className="col-md-12 mb-2 text-center">
                            <h3 className="text-blue">Tools and programming language</h3>
                        </div>
                        <div className="col-md-10 offset-md-1 text-center">
                            <div className="kotak skill-js">
                                <img src="/assets/react.svg" />
                            </div>
                            <div className="kotak skill-react">
                                <img src="/assets/react.svg" />
                            </div>
                            <div className="kotak skill-sass">
                                <img src="/assets/react.svg" />
                            </div>
                        </div>
                        <div className="col-md-12  text-center">
                            <div className="kotak skill-node">
                                <img src="/assets/react.svg" />
                            </div>
                            <div className="kotak skill-go">
                                <img src="/assets/react.svg" />
                            </div>
                            <div className="kotak skill-git">
                                <img src="/assets/react.svg" />
                            </div>
                        </div>
                        <div className="col-md-12 mb-2 mt-4 text-center">
                            <h3 className="text-blue">Industry Knowledge</h3>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="ik">
                                <p>Frontend Development</p>
                                <p>Backend Development</p>
                                <p>Responsive web design</p>
                                <p>Mockup-to-code conversion</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		</Layout>
    )
  }
}

export default index
