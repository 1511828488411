import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../static/assets/App.scss'
import { Link } from 'gatsby';

class Layout extends Component<{},{
    navbars: Array<{
        shown: boolean
        url: string
        label: string
    }>
}> {
    private navbar: React.RefObject<any>

    constructor(props:any) {
        super(props)
        this.navbar = React.createRef();
        this.state = {
            navbars: [
                {shown: false, url: "/about/", label: "About"},
                {shown: false, url: "/", label: "Overview"},
                {shown: false, url: "/skills/", label: "Skills"},
                // {shown: false, url: "/contact", label: "Contact"},
                {shown: false, url: "/projects/", label: "Projects"},
            ],
        }
    }
    componentDidMount() {
        let {navbars} = this.state
        var ujungNav = navbars.length - 1
        var param = window.location.pathname
        navbars.forEach((val, idx) => {
            if (val.url == param) {
                navbars[idx].shown = true
                if (idx == 0) {
                    navbars[ujungNav].shown = true
                    navbars[idx + 1].shown = true
                    navbars.unshift(navbars.pop())
                    this.setState({})
                    return false
                }
                if (idx == ujungNav) {
                    navbars[0].shown = true
                    navbars[idx - 1].shown = true
                    navbars.push(navbars.shift())
                    this.setState({})
                    return false
                }
                navbars[idx + 1].shown = true
                navbars[idx - 1].shown = true
            }
        })
        this.setState({navbars: navbars})
    }
    render() {
        return (
            <div className="main-section">
                <section className="cus-navbar text-center">
                    <ul>
                        {this.state.navbars.map((val, idx) => {
                            if (val.shown) {
                                return (
                                    <Link key={idx} activeClassName="active" to={val.url}>
                                        {val.label}
                                    </Link>
                                )
                            }
                        })}
                    </ul>
                </section>
                {this.props.children}
                <footer className="text-center pb-2">
                    <p>Crafted with <span>&#10084;</span> by Muhammad Khotib</p>
                </footer>
            </div>
        )
    }
}

export default Layout
